import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Paginate from "../components/paginate"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

export const data = graphql`
  query newsPostsQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fileAbsolutePath: { regex: "/news/" } }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          id
          excerpt
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
const NewsPage = ({ data, pageContext }) => {
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  return (
    <Layout>
      <SEO title="News" />
      <Hero pageTitle="News" />
      <section id="result" className="section-1 offers">
        <div className="container">
          <div className="row items">
            <div className="col-lg-10 col-md-10 mx-auto">
              {data.allMarkdownRemark.edges.map(edge => {
                return (
                  <div key={edge.node.id}>
                    <div className="row d-flex align-items-center">
                      <div className="col-md-6 col-lg-4 ">
                        <h4>{edge.node.frontmatter.title}</h4>
                        <Link to={`/news${edge.node.fields.slug}`}>
                          <Img
                            fluid={
                              edge.node.frontmatter.image.childImageSharp.fluid
                            }
                            alt=""
                            className="d-block d-md-none"
                          />
                        </Link>
                        <p>{edge.node.excerpt}</p>
                        <Link to={`/news${edge.node.fields.slug}`}>
                          <button className="btn outline-button">
                            Read more
                          </button>
                        </Link>
                        <br />
                      </div>
                      <div className="col-md-6 col-lg-8">
                        <Link to={`/news${edge.node.fields.slug}`}>
                          <Img
                            fluid={
                              edge.node.frontmatter.image.childImageSharp.fluid
                            }
                            alt=""
                            className="d-none d-md-block"
                          />
                        </Link>
                      </div>
                    </div>
                    <hr />
                  </div>
                )
              })}
              <Paginate
                isFirst={isFirst}
                isLast={isLast}
                nextPage={nextPage}
                prevPage={prevPage}
                currentPage={currentPage}
                numPages={numPages}
                postType="/news/"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NewsPage
